import React, { Component } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import Menu from "./Menu"
import { theme } from "../styles/global-styles"

const NavSpans = styled.ul`
  position: relative;
  cursor: pointer;
  width: 30px;
  height: 25px;
  list-style: none;
  margin: 0;

  li {
    border-radius: 2px;
    width: 100%;
    height: 4px;
    transition: background-color 0.8s;
  }

  li:nth-of-type(1) {
    animation: outTop 0.8s backwards;
    animation-direction: reverse;
    @media only screen and (max-width: 699px) {
      background-color: ${theme.colors.accentOne};
    }
  }

  li:nth-of-type(2) {
    margin: 5px 0;
    animation: outMiddle 0.8s backwards;
    animation-direction: reverse;
    @media only screen and (max-width: 699px) {
      background-color: ${theme.colors.main};
    }
  }

  li:nth-of-type(3) {
    animation: outBottom 0.8s backwards;
    animation-direction: reverse;
    @media only screen and (max-width: 699px) {
      background-color: ${theme.colors.accentTwo};
    }
  }

  @media only screen and (max-width: 699px) {
    li {
      background-color: ${theme.colors.light};
    }
  }
`

class Hamburger extends Component {
  constructor(props) {
    super(props)

    this.state = {
      toggleHamburger: false,
    }
  }

  toggleHamburger() {
    let openHamburger = css`
      z-index: 400;
      li:nth-of-type(1) {
        animation: inTop 0.8s forwards;
      }
      li:nth-of-type(2) {
        animation: inMiddle 0.8s forwards;
      }
      li:nth-of-type(3) {
        animation: inBottom 0.8s forwards;
      }

      @keyframes inMiddle {
        50% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(45deg);
        }
      }

      @keyframes outMiddle {
        50% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(45deg);
        }
      }

      @keyframes inTop {
        0% {
          transform: translateY(0px) rotate(0deg);
        }
        50% {
          transform: translateY(9px) rotate(0deg);
        }
        100% {
          transform: translateY(9px) rotate(135deg);
        }
      }

      @keyframes outTop {
        0% {
          transform: translateY(0px) rotate(0deg);
        }
        50% {
          transform: translateY(9px) rotate(0deg);
        }
        100% {
          transform: translateY(9px) rotate(135deg);
        }
      }

      @keyframes inBottom {
        0% {
          transform: translateY(0px) rotate(0deg);
        }
        50% {
          transform: translateY(-9px) rotate(0deg);
        }
        100% {
          transform: translateY(-9px) rotate(135deg);
        }
      }

      @keyframes outBottom {
        0% {
          transform: translateY(0px) rotate(0deg);
        }
        50% {
          transform: translateY(-9px) rotate(0deg);
        }
        100% {
          transform: translateY(-9px) rotate(135deg);
        }
      }
    `
    if (this.state.toggleHamburger) {
      this.setState({
        toggleHamburger: false,
      })
    } else {
      this.setState({
        toggleHamburger: openHamburger,
      })
    }
  }

  render() {
    return (
      <div>
        <Menu slideMenuTriggered={this.state.toggleHamburger ? "0" : "-110%"} />
        <nav
          onClick={() => this.toggleHamburger()}
          css={this.state.toggleHamburger}
          aria-label="Desktop Navigation"
          role="navigation"
        >
          <NavSpans
            css={css`
              z-index: 400;
            `}
          >
            <li
              css={css`
                background-color: ${this.state.toggleHamburger
                  ? theme.colors.dark
                  : this.props.logoAndMenuColor};
              `}
            ></li>
            <li
              css={css`
                background-color: ${this.state.toggleHamburger
                  ? theme.colors.dark
                  : this.props.logoAndMenuColor};
              `}
            ></li>
            <li
              css={css`
                background-color: ${this.state.toggleHamburger
                  ? theme.colors.dark
                  : this.props.logoAndMenuColor};
              `}
            ></li>
          </NavSpans>
        </nav>
      </div>
    )
  }
}

export default Hamburger
