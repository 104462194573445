/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Global, css } from "@emotion/core"
import { withTheme, ThemeProvider } from "emotion-theming"
import { theme } from "../styles/global-styles"

const makeGlobalStyles = theme => css`
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
 ${theme.typography.fontImport} 
 ${theme.meyerReset.reset}
 ${theme.GlobalTypography}
  h1 {
    font: ${theme.typography.h1};
    margin-bottom: ${theme.margs.l};
  @media only screen and (max-width: 959px) {
    font-size: ${theme.fontBreaks.h1md};
  }
  @media only screen and (max-width: 699px) {
    margin-bottom: ${theme.margs.sm};
    font-size: ${theme.fontBreaks.h1sm};
  }
  }
  h2 {
    font: ${theme.typography.h2};
    margin-bottom: ${theme.margs.l};
  margin-top: ${theme.margs.sxl};
  @media only screen and (max-width: 959px) {
    font-size: ${theme.fontBreaks.h2md};
  }
  @media only screen and (max-width: 699px) {
    margin-bottom: ${theme.margs.sm};
    font-size: ${theme.fontBreaks.h2sm};
  }
  }
  h3 {
    font: ${theme.typography.h3};
    margin-bottom: ${theme.margs.l};
  margin-top: ${theme.margs.sxl};
  @media only screen and (max-width: 959px) {
    font-size: ${theme.fontBreaks.h3md};
  }
  @media only screen and (max-width: 699px) {
    margin-bottom: ${theme.margs.sm};
    font-size: ${theme.fontBreaks.h3sm};
  }
  }
  h4 {
    font: ${theme.typography.h4};
    margin-bottom: ${theme.margs.l};
  margin-top: ${theme.margs.sxl};
  @media only screen and (max-width: 959px) {
    font-size: ${theme.fontBreaks.h4md};
  }
  @media only screen and (max-width: 699px) {
    margin-bottom: ${theme.margs.sm};
    font-size: ${theme.fontBreaks.h4sm};
  }
  }
  h5 {
    font: ${theme.typography.h5};
    margin-bottom: ${theme.margs.l};
  margin-top: ${theme.margs.sxl};
  @media only screen and (max-width: 699px) {
    margin-bottom: ${theme.margs.sm};
  }
  }
  h6 {
    font: ${theme.typography.h6};
    margin-bottom: ${theme.margs.l};
  margin-top: ${theme.margs.sxl};
  @media only screen and (max-width: 699px) {
    margin-bottom: ${theme.margs.sm};
  }
  }

  ul, ol {
    margin-left: 3rem;
  margin-bottom: ${theme.margs.l};
  list-style: initial;
  }

  li {
    p {
    margin-bottom: 0.3rem;
  }
  }

  a {
    color: ${theme.colors.accentOne};
  text-decoration: none;
  :hover {
    color: ${theme.colors.main};
    transition: 0.3s;
  }
  }

  blockquote {
    color: ${theme.colors.dark};
  border-left: 0.5rem solid ${theme.colors.main};
  padding-left: 1rem;
  margin: 5rem 0 5rem 2rem;
  p {
    font-size: 3rem;
    font-family: ${theme.fontFaces.headerFont};
    line-height: 4rem;
  }
  @media only screen and (max-width: 699px) {
    margin: 1rem 0 1rem 2rem;
    p {
      font-size: 2rem;
      line-height: 1.5;
    }
  }
  }

  em {
  font-style: italic;
  }

  hr {
    background-color: ${theme.colors.main};
  height: 4px;
  border: none;
  width: 20%;
  margin: ${theme.margs.sxl} auto;
  }

  body,
  p,
  ol,
  ul,
  li {
    font: ${theme.typography.p1};
  }
  .bold {
    font-size: 200px;
  }

  p {
    line-height: 1.8;
    margin-bottom: ${theme.margs.l};
  margin-top: 0;
  }

  pre {
  content: "";
  clear: both;
  display: table;
  }

  pre {
    tab-size: 16;
  }

  code span {
    max-width: 100%;
  }

  /* Start React-Syntax-Highligter Styles */
  .hljs {
    padding: 1rem 3rem 1rem 3rem;
    overflow: auto;
    background: ${theme.colors.dark};
    color: ${theme.colors.light};
    width: 100%;
    font-size: 1rem;
    white-space: pre-wrap;
    @media only screen and (max-width: 599px) {
      padding: 1rem 1rem 1rem 1rem;
      font-size: .8rem;
  }


  }
  .hljs-link {
    color: ${theme.colors.main};
  }
  .hljs-emphasis {
    color: ${theme.colors.main};
    font-style: italic;
  }
  .hljs-attribute {
    color: ${theme.colors.main};
  }
  .hljs-addition {
    color: ${theme.colors.main};
  }
  .hljs-strong {
    color: ${theme.colors.main};
    font-weight: bold;
  }
  .hljs-string {
    color: ${theme.colors.main};
  }
  .hljs-deletion {
    color: ${theme.colors.main};
  }
  .hljs-quote {
    color: red;
    font-style: italic;
  }
  .hljs-comment {
    color: red;
    font-style: italic;
  }
  .hljs-section {
    color: #900;
  }
  .hljs-title {
    color: #900;
  }
  .hljs-class .hljs-title {
    color: #458;
  }
  .hljs-type {
    color: #458;
  }
  .hljs-variable {
    color: red;
  }
  .hljs-template-variable {
    color: #336699;
  }
  .hljs-bullet {
    color: #997700;
  }
  .hljs-meta {
    color: #3344bb;
  }
  .hljs-code {
    color: ${theme.colors.accentOne};
  }
  .hljs-number {
    color: ${theme.colors.accentOne};
  }
  .hljs-literal {
    color: ${theme.colors.accentOne};
  }
  .hljs-keyword {
    color: ${theme.colors.accentOne};
  }
  .hljs-selector-tag {
    color: ${theme.colors.accentOne};
  }
  .hljs-regexp {
    background-color: #fff0ff;
    color: #880088;
  }
  .hljs-symbol {
    color: #990073;
  }
  .hljs-tag {
    color: #007700;
  }
  .hljs-name {
    color: #007700;
  }
  .hljs-selector-id {
    color: #007700;
  }
  .hljs-selector-class {
    color: #007700;
  }

  .main-article-wrapper {
    width: 100%;
    margin: 4em 4rem 0 4rem;
    max-width: ${theme.widths.mainContainer};
    @media only screen and (max-width: 959px) {
      max-width: ${theme.widths.smContainer};
    }
    @media only screen and (max-width: 740px) {
      margin: 4em 4rem 0 4rem;
    }
    @media only screen and (max-width: 599px) {
      margin: 1.5em 1.5rem 0 1.5rem;
    }
  }
`

const GlobalStyles = withTheme(({ theme }) => (
  <Global styles={makeGlobalStyles(theme)} />
))

// DELETE TO HERE

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        {/* <Header siteTitle={data.site.siteMetadata.title} /> */}
        <div>
          <main>{children}</main>
          <footer>
            {/* © {new Date().getFullYear()}, Joshua Maddox. Built With React & Gatsby */}
          </footer>
        </div>
      </ThemeProvider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
