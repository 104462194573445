import React from "react"
import styled from "@emotion/styled"
import { theme } from "../styles/global-styles"

const FullWidthHeroWrapper = styled.section`
  width: 100%;
  background-color: ${props => props.backgroundColor};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`

const FwhHeader = styled.h1`
  color: ${props => props.headerColor};
  margin: 0;
  padding: 5rem 0;
  max-width: 65%;
  text-align: center;
  position: relative;
  z-index: 10;
  font-size: ${props => {
    if (props.headerLength > 50) {
      return "3rem;"
    } else return "5rem;"
  }};
  @media only screen and (max-width: 975px) {
    font-size: ${props => {
      if (props.headerLength > 50) {
        return "3rem"
      } else return "4rem"
    }};
  }
  @media only screen and (max-width: 700px) {
    font-size: ${props => {
      if (props.headerLength > 50) {
        return "2rem"
      } else return "3rem"
    }};
    padding: 3rem 0;
  }

  @media only screen and (max-width: 420px) {
    font-size: ${props => {
      if (props.headerLength > 50) {
        return "1.2rem"
      } else return "1.7rem"
    }};
    padding: 3rem 0;
  }
`
const SVGWrapper = styled.div`
  position: absolute;
  width: 80%;
  height: 80%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
  display: flex;

  @media only screen and (max-width: 975px) {
    height: 90%;
    width: 90%;
  }

  @media only screen and (max-width: 500px) {
    height: 100%;
    width: 90%;
  }
`
const SVGOne = styled.svg`
  fill: ${props => props.color};
  opacity: 0.8;
  height: 100%;
  margin-right: 4rem;
  width: 100%;

  &:last-of-type {
    margin-right: 0;
  }

  @media only screen and (max-width: 1600px) {
    margin-right: 3.2rem;
  }

  @media only screen and (max-width: 1350px) {
    margin-right: 2.5rem;
  }

  @media only screen and (max-width: 1200px) {
    margin-right: 1.7rem;
  }

  @media only screen and (max-width: 1040px) {
    margin-right: 1.2rem;
  }

  @media only screen and (max-width: 975px) {
    margin-right: 0.5rem;
  }
`

const FullWidthHero = props => {
  return (
    <FullWidthHeroWrapper backgroundColor={props.backgroundColor}>
      <SVGWrapper>
        <SVGOne color={theme.colors.accentOne} viewBox="0 0 90 450">
          <polygon points="0 0, 47 4, 90 0, 85 20, 90 120, 82 290, 90 450, 75 439, 36 450, 0 450, 10 397, 0 237, 5 147, 0 58, 0 0" />
        </SVGOne>
        <SVGOne color={theme.colors.accentOne} viewBox="0 0 90 450">
          <polygon points="0 0, 59 7, 90 0, 81 70, 90 190, 82 360, 90 450, 72 425, 14 450, 0 450, 15 327, 0 137, 5 48, 0 58, 0 0" />
        </SVGOne>
        <SVGOne color={theme.colors.main} shift={"-30%"} viewBox="0 0 90 450">
          <polygon points="0 0, 47 4, 90 0, 85 20, 90 120, 82 290, 90 450, 75 439, 36 450, 0 450, 10 397, 0 237, 5 147, 0 58, 0 0" />
        </SVGOne>
        <SVGOne color={theme.colors.accentTwo} viewBox="0 0 90 450">
          <polygon points="0 0, 47 4, 90 0, 85 20, 90 120, 82 290, 90 450, 75 439, 36 450, 0 450, 10 397, 0 237, 5 147, 0 58, 0 0" />
        </SVGOne>
        <SVGOne color={theme.colors.accentTwo} viewBox="0 0 90 450">
          <polygon points="0 0, 47 4, 90 0, 85 20, 90 120, 82 290, 90 450, 75 439, 36 450, 0 450, 10 397, 0 237, 5 147, 0 58, 0 0" />
        </SVGOne>
        <SVGOne color={theme.colors.accentTwo} viewBox="0 0 90 450">
          <polygon points="0 0, 47 4, 90 0, 85 20, 90 120, 82 290, 90 450, 75 439, 36 450, 0 450, 10 397, 0 237, 5 147, 0 58, 0 0" />
        </SVGOne>
        <SVGOne color={theme.colors.main} viewBox="0 0 90 450">
          <polygon points="0 0, 47 4, 90 0, 85 20, 90 120, 82 290, 90 450, 75 439, 36 450, 0 450, 10 397, 0 237, 5 147, 0 58, 0 0" />
        </SVGOne>
        <SVGOne color={theme.colors.main} viewBox="0 0 90 450">
          <polygon points="0 0, 47 4, 90 0, 85 20, 90 120, 82 290, 90 450, 75 439, 36 450, 0 450, 10 397, 0 237, 5 147, 0 58, 0 0" />
        </SVGOne>
        <SVGOne color={theme.colors.accentTwo} viewBox="0 0 90 450">
          <polygon points="0 0, 47 4, 90 0, 85 20, 90 120, 82 290, 90 450, 75 439, 36 450, 0 450, 10 397, 0 237, 5 147, 0 58, 0 0" />
        </SVGOne>
        <SVGOne color={theme.colors.accentOne} viewBox="0 0 90 450">
          <polygon points="0 0, 47 4, 90 0, 85 20, 90 120, 82 290, 90 450, 75 439, 36 450, 0 450, 10 397, 0 237, 5 147, 0 58, 0 0" />
        </SVGOne>
        <SVGOne color={theme.colors.accentOne} viewBox="0 0 90 450">
          <polygon points="0 0, 47 4, 90 0, 85 20, 90 120, 82 290, 90 450, 75 439, 36 450, 0 450, 10 397, 0 237, 5 147, 0 58, 0 0" />
        </SVGOne>
      </SVGWrapper>
      <FwhHeader
        headerLength={props.headerText.length}
        headerColor={props.headerColor}
      >
        {props.headerText}
      </FwhHeader>
    </FullWidthHeroWrapper>
  )
}

export default FullWidthHero
