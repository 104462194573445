import React from "react"
import styled from "@emotion/styled"
import Hamburger from "./Hamburger"
import { theme } from "../styles/global-styles"
import { Link } from "react-scroll"

//Delete to here

const OuterNavWrapper = styled.div`
  height: 3em;
  width: 100%;
  background-color: ${props => props.color};
  @media only screen and (max-width: 699px) {
    background-color: ${theme.colors.dark};
  }
`

const InnerNavWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 4rem;
  margin-right: 4rem;
  height: 100%;
  z-index: 10;
  @media only screen and (max-width: 960px) {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }

  @media only screen and (max-width: 699px) {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    background-color: ${theme.colors.dark};
  }

  @media only screen and (max-width: 450px) {
    margin-left: 1rem;
    margin-right: 1rem;
  }
`
const LogoWrapper = styled.div`
  a {
    text-decoration: none;
    font-family: Oswald, sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    color: ${props => props.color};
  }

  @media only screen and (max-width: 699px) {
    a {
      color: ${theme.colors.light};
    }
  }
`
const LinkWrapper = styled.ul`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;

  li {
    font-family: ${theme.fontFaces.headerFont};
    list-style: none;
    margin-right: 2rem;
    font-size: 1rem;
    &:last-of-type {
      margin-right: 0;
    }
    &:hover {
      color: ${theme.colors.main};
    }
    transition: 0.5s;
  }
`

const MainNav = ({ logoAndMenuColor, bgColor, hamburgerOn, stickyOn }) => {
  return (
    <OuterNavWrapper color={bgColor} stickyOn={stickyOn}>
      <InnerNavWrapper>
        <LogoWrapper color={logoAndMenuColor}>
          <span>
            <a href="/">Joshua Maddox</a>
          </span>
        </LogoWrapper>

        {hamburgerOn ? (
          <Hamburger logoAndMenuColor={logoAndMenuColor} />
        ) : (
          <LinkWrapper>
            <li>
              <Link
                activeClass={"someActiveClass"}
                to={"resume"}
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                Resume
              </Link>
            </li>
            <li>
              <Link
                activeClass={"someActiveClass"}
                to={"musings"}
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                Musings
              </Link>
            </li>
          </LinkWrapper>
        )}
      </InnerNavWrapper>
    </OuterNavWrapper>
  )
}

export default MainNav
