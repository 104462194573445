import React from "react"
import { theme } from "../styles/global-styles"
import styled from "@emotion/styled"

const DateHeader = styled.p`
  color: ${props => props.color};
  margin-bottom: 2rem;
  margin-top: 0.5rem;
  padding-bottom: 0;
  font-size: 1rem;
  font-family: ${theme.fontFaces.bodyFont};
`

const ContentDate = ({ date, color }) => {
  return <DateHeader color={color}>{date}</DateHeader>
}

export default ContentDate
