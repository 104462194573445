import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { theme } from "../styles/global-styles"
import { Link } from "gatsby"

const MenuItems = styled.li`
  color: ${theme.colors.dark};
  font-family: Oswald, sans-serif;
  font-size: 4rem;
  text-transform: uppercase;
  font-weight: 700;
  list-style: none;
  @media only screen and (max-width: 699px) {
    font-size: 2.5rem;
    color: ${theme.colors.light};
  }
  @media only screen and (max-width: 500px) {
    font-size: 2.5rem;
  }
`

const Menu = props => {
  return (
    <div
      css={css`
        width: 100vw;
        height: 100vh;
        background-color: ${theme.colors.light};
        position: absolute;
        z-index: 100;
        top: ${props.slideMenuTriggered};
        left: 0;
        transition: 0.8s;
        display: flex;
        justify-content: center;
        align-items: center;
        @media only screen and (max-width: 699px) {
          font-size: 2.5rem;
          background-color: ${theme.colors.dark};
        }
      `}
    >
      <div>
        <nav>
          <ul
            css={css`
              margin: 0;
            `}
          >
            <MenuItems>
              <Link to="/#resume">Resume</Link>
            </MenuItems>
            {/* <MenuItems>Projects</MenuItems> */}
            <MenuItems>
              <Link to="/#musings">Musings</Link>
            </MenuItems>
          </ul>
        </nav>
      </div>
    </div>
  )
}

export default Menu
