export const breakpoints = {
  mobile: `599px`,
  tabletUp: `600px`,
  tabletLandscapeUp: `900px`,
  desktopUp: `1200px`,
  xlDesktopUp: `1800px`,
  helper1100: `1100px`,
}

export const widths = {
  mainContainer: `960px`,
  smContainer: `650px`,
}

export const colors = {
  main: "#FFCB45",
  mainHover: "#FFD65C",
  accentOne: "#00DDFD",
  accentOneHover: "#40E6FE",
  accentTwo: "#FF7245",
  accentTwoHover: "#FF9574",
  dark: "#2C2F3B",
  darkHover: "#4C4E58",
  medium: "#353842",
  mediumHover: "#686A71 ",
  light: "#F4F5F5",
  white: "#ffffff",
}

export const fontFaces = {
  headerFont: "Oswald, sans-serif; text-transform: uppercase;",
  bodyFont: "Quattrocento, serif;",
}

export const fontBreaks = {
  h1sm: "3rem",
  h1md: "4rem",
  h2sm: "2.3rem",
  h2md: "3.3rem",
  h3sm: "1.8rem",
  h3md: "2rem",
  h4sm: "1.35rem",
  h4md: "1.55rem",
}

/* Must use the font shorthand in the style being described */

export const margs = {
  textSM: "margin-top: .75rem;",
  textMD: "margin-top: 1rem;",
  textXL: "margin-top: 1.5rem;",
  sectionSM: "margin-top: 3rem;",
  sectionMD: "margin-top: 4.5rem;",
  sectionLG: "margin-top: 5.5rem;",
  sectionXL: "margin-top: 6.5rem;",
  center: "margin: 0 auto;",
  sm: "1rem",
  md: "1.5rem",
  l: "2rem",
  xl: "3rem",
  sxl: "5rem",
}

export const mixIns = {
  pTags: `color: ${colors.dark}; ${margs.text}`,
  lists: `color: ${colors.dark}; ${margs.textSM}`,
  hTags: `color: ${colors.dark}; text-transform: uppercase; ${margs.textXL}`,
}

export const typography = {
  fontImport: `@import url("https://fonts.googleapis.com/css?family=Oswald:300,700&display=swap")
  @import url("https://fonts.googleapis.com/css?family=Quattrocento:400,700&display=swap")`,
  h1: `700 6rem/100% ${fontFaces.headerFont} ${mixIns.hTags}`,
  h2: `700 4rem/120% ${fontFaces.headerFont} ${mixIns.hTags}`,
  h3: `700 2.5rem/100%  ${fontFaces.headerFont} ${mixIns.hTags}`,
  h4: `700 1.75rem/120% ${fontFaces.headerFont} ${mixIns.hTags}`,
  h5: `700 1.25rem/120% ${fontFaces.headerFont} ${mixIns.hTags}`,
  h6: `700 1rem/120% ${fontFaces.headerFont} ${mixIns.hTags}`,
  p1: `400 1.25rem/135% ${fontFaces.bodyFont} ${mixIns.pTags}`,
  p2: `400 1.5rem/135% ${fontFaces.bodyFont} ${mixIns.pTags}`,
  p3: `400 2rem/135% ${fontFaces.bodyFont} ${mixIns.pTags}`,
  p4: `400 2.5rem/135% ${fontFaces.bodyFont} ${mixIns.pTags}`,
  p5: `400 3rem/135% ${fontFaces.bodyFont} ${mixIns.pTags}`,
  p6: `400 3.5rem/135% ${fontFaces.bodyFont} ${mixIns.pTags}`,
  cardLinks: `400 .8rem/100% ${fontFaces.headerFont}; letter-spacing: .2rem;`,
}

//If we create a Component in Emotion and put it into global can we access it as we would an html element?

export const helpers = {
  mediaQueries: screenWidth => {
    if (!screenWidth) {
      return ""
    } else {
      return `@media only screen and (min-width: ${breakpoints[screenWidth]})`
    }
  },
}

export const meyerReset = {
  reset: `
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed, 
  figure, figcaption, footer, header, hgroup, 
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  article, aside, details, figcaption, figure, 
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  body {
    line-height: 1;
    font-weight: normal;
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  img {
    max-width: 100%;
    height: auto;
  }
  `,
}

export const theme = {
  colors,
  breakpoints,
  fontFaces,
  helpers,
  typography,
  meyerReset,
  mixIns,
  margs,
  widths,
  fontBreaks,
}
