import React from "react"
import styled from "@emotion/styled"
import { theme } from "../styles/global-styles"
import ContentDate from "./ContentDate"

const SectionHeaderWrapper = styled.div`
  margin-bottom: ${props => props.margBottom};
  position: relative;
`
const SectionText = styled.h2`
  font-size: 3rem;
  position: relative;
  z-index: 10;
  @media only screen and (max-width: 740px) {
    font-size: 1.7rem;
  }
`

const SectionSVG = styled.svg`
  width: 200px;
  height: 40px;
  position: absolute;
  top: 30px;
  left: 0px;
  z-index: 1;
  @media only screen and (max-width: 740px) {
    top: 15px;
    font-size: 1.7rem;
  }
`

const SmallSectionHeader = ({
  headerTitle,
  pointsForSVG,
  color,
  margBottom,
}) => {
  return (
    <SectionHeaderWrapper margBottom={margBottom}>
      <SectionSVG fill={color}>
        <polygon
          points={
            pointsForSVG
              ? pointsForSVG
              : "0 0, 30 4, 70 0, 120 10, 160 0, 200 5, 200 20, 197 60, 200 40, 175 38, 145 36, 95 40, 38 38, 0 40, 5 45"
          }
        />
      </SectionSVG>
      <SectionText>{headerTitle}</SectionText>
    </SectionHeaderWrapper>
  )
}

export default SmallSectionHeader
