import React from "react"
import styled from "@emotion/styled"
import { theme } from "../styles/global-styles"

const FooterWrapper = styled.footer`
  width: 100%;
  height: 6rem;
  background-color: ${theme.colors.dark};
  margin-top: ${theme.margs.sxl};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media only screen and (max-width: 799px) {
    margin-top: ${theme.margs.l};
  }
  @media only screen and (max-width: 599px) {
    margin-top: ${theme.margs.sxl};
  }
`
const FooterText = styled.p`
  color: ${theme.colors.light};
  margin: 0 5rem 0 0;
  @media only screen and (max-width: 699px) {
    width: 100%;
    margin-left: 1.5rem;
  }
`

const Footer = props => {
  return (
    <FooterWrapper>
      <FooterText>React. Gatsby. Emotion. Netlify. Otifan.</FooterText>
    </FooterWrapper>
  )
}

export default Footer
